import {useNuxtApp} from 'nuxt/app'
import {defineNuxtRouteMiddleware} from "nuxt/app";
import {useNotification} from "@/composables/useNotification";

export default defineNuxtRouteMiddleware((to, from) => {
    const app = useNuxtApp()
    if (to.meta?.mustPossessAbility) {
        if (!app.$abilitySingleton.can(to.meta.mustPossessAbility.action, to.meta.mustPossessAbility.subject)) {
            useNotification().warning(app.$i18n.t('canNotAccessRoute'))
            return navigateTo(useLocalePath()('/'))
        }
    }
})